@import '/src/styles-imports/shared';


/* MAINLAYOUT */
.mainLayout {
  display: flex;
  min-height: 100vh;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .mainNav {
    box-sizing: border-box;
    width: $mainNavWidth;
    height: 100vh;

    background-color: white;
    z-index: zIndex('MainNavigation');

    @include transitionLong(margin-left);

    &.closed {
      margin-left: -$mainNavWidth;
    }

    @include mqSmaller($bpL) {
      position: absolute;
    }

    @include mqSmaller($bpS) {
      // header will move over main nav
      top: $headerHeightXs;
      height: calc(100vh - #{$headerHeightXs}) ;
      // full width
      width: 100vw ;
      // no margin & animation
      margin-left: 0 !important;

      @include transitionLong(opacity, transform, background-color);

      &.closed {
        user-select: none;
        pointer-events: none;
        transform: translateY(-20px) ;
        opacity: 0 ;
      }
    }
  }

  .main {
    width: calc(100vw - #{$mainNavWidth});
    height: 100vh;

    overflow: hidden;

    background-color: $colorGrey6;
    @include transitionLong(width);

    &.fullWidth {
      width: 100vw;
    }
    &.subNavIsOpen {
      .header {
        height: $headerHeight + $subNavHeight;
      }
      .page {
        height: calc(100vh - #{$headerHeight + $subNavHeight});
      }
      @include mqSmaller($bpS) {
        .header {
          height: $headerHeightXs + $subNavHeightXs;
        }
        .page {
          height: calc(100vh - #{$headerHeightXs + $subNavHeightXs})
        }
      }
    }

    @include mqSmaller($bpL) {
      width: 100vw !important;
    }
  }

  .header {
    width: 100%;
    height: $headerHeight;

    background-color: white;

    @include mqSmaller($bpS) {
      height: $headerHeightXs + $headerHeight;
    }
  }

  .page {
    height: calc(100vh - #{$headerHeight});

    @include mqSmaller($bpS) {
      height: calc(100vh - #{$headerHeightXs + $headerHeight})
    }
  }

  .nestedPage {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;

    @include transitionLong(background-color);

    &.white {
      background-color: white;
    }
  }

  .pageWrapper {
    min-height: calc(100% - #{$footerHeight});

    @include mqSmaller($bpS) {
      min-height: calc(100% - #{$footerHeightXs});
    }
  }

  .footer {
    @include gridMarginsAsPaddings;

    height: $footerHeight;
    background-color: white;

    // same animation as PageWrapper
    @include animation(slideUp);

    @include mqSmaller($bpS) {
      height: $footerHeightXs;
    }
  }
}
