@import '/src/styles-imports/shared';

$minPadding: 2px;

/* CHIP */
.chip {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  gap: $spaceXxs;
  padding: $minPadding $spaceXs;
  
  overflow: hidden;
  word-break: break-word;
  user-select: none;
  
  @include type('LabelButton');
  color: $colorPrimary1;
  border: 1px solid $colorPrimary1;
  border-radius: 18px;

  @include transition(border-color, color, opacity, transform); // added opacity, transform for BluCSSTransition in HotChips

  // CHILDREN
  .circleContainer {
    margin-left: -$spaceXs + $minPadding;
  }

  .svgContainer {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: $colorPrimary1;
      @include transition(fill);
    }
  }

  // 'look' modifiers
  &.static {
    pointer-events: none;
    color: $colorGrey01;
    border: 1px solid $colorGrey01;
    svg path {
      fill: $colorGrey01;
    }
  }

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      border-color: $colorDanger1;
      color: $colorDanger1;

      svg path {
        fill: $colorDanger1;
      }

      [class*=Circle] {
        color: $colorDanger1;
        background-color: $colorDanger3;
      }
    }
  }
}



