@import '/src/styles-imports/shared';

/* DimensionResult */
.dimensionResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .diagramBlock {
    margin-top: $spaceXs;

    // result value
    & > div:first-child {
      @include type('Copy');
      color: $colorPrimary1;
      text-align: right;
    }
    .diagram {
      margin-top: $spaceXs;
    }
  }

  .peerResult {
    margin-top: $spaceM;

    &:first-child {
      margin-top: 40px;
    }

    & > svg {
      margin-top: $spaceXs;
    }

    .textContent {
      display: flex;
      justify-content: space-between;
      @include type('Copy');

      & > span:last-child {
        color: $colorPrimary1;
      }
    }
  }

  .lowestHighestValues {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    margin-top: $spaceXxs;
    @include type('Hint');

    & > div {
      &:last-child {
        margin-left: 39px;
      }

      & > span:last-child {
        padding-left: 6px;
        color: $colorPrimary1;
      }
    }
  }

  .legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: $spaceXxs;

    .lItem {
      display: flex;
      align-items: center;
      margin-top: $spaceXxs;

      &:hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: $spaceS;
      }

      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &.blue {
          background-color: $colorBlue03;
        }
        &.yellow {
          background-color: $colorYellow03;
        }
        &.green {
          background-color: $colorGreen03;
        }
        &.red {
          background-color: $colorRed03;
        }
        &.purple {
          background-color: $colorPurple02;
        }
        &.grey {
          background-color: $colorGrey01;
        }
      }

      .text {
        @include type('Hint');
        padding-left: $spaceXxxs;
        .blue {
          color: $colorBlue03;
        }
      }
    }
  }

}
