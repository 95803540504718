@import '/src/styles-imports/shared';

/* PeerGroupDescription */
.peerGroupDescription {
  margin-top: $spaceXs;

  &:first-child {
    margin-top: 0;
  }

  .participants {
    margin-top: $spaceXs;
    .participantsGroup {
      margin-top: $spaceXs;
      .participantsChips {
        margin-top: $spaceXs;
        display: flex;
        flex-wrap: wrap;
        gap: 12px $spaceXxs;
      }
    }

    .noPeers {
      color: $colorGrey03; 
      font-style: italic;
    }
  }
}
