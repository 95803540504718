@import '/src/styles-imports/shared';

@mixin markerPulsingAnimation($name, $color) {
  $maxRadius: 7.5;
  @keyframes #{$name} {
    0% {
      r: $maxRadius / 2;
    }
    50% {
      fill: $color;
      r: $maxRadius;
    }
    100% {
      r: $maxRadius / 2;
    }
  }
}

/* LineDiagram */
.lineDiagram {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  svg {
    width: 100%;
    overflow: initial;
  }

  .grey {
    fill: $colorGrey3;
  }

  .marker {
    &.primary, &.blue {
      fill: $colorBlue03;
      &.light {
        fill: $colorBlue04;
      }

      &.animating {
        @include markerPulsingAnimation(scaleBlue, $colorBlue02);
        animation: scaleBlue 1.5s infinite ease-in-out;
      }
    }
    &.yellow {
      fill: $colorYellow03;
      &.light {
        fill: $colorYellow04;
      }

      &.animating {
        @include markerPulsingAnimation(scaleYellow, $colorYellow02);
        animation: scaleYellow 1.5s infinite ease-in-out;
      }
    }
    &.green {
      fill: $colorGreen03;
      &.light {
        fill: $colorGreen04;
      }

      &.animating {
        @include markerPulsingAnimation(scaleGreen, $colorGreen02);
        animation: scaleGreen 1.5s infinite ease-in-out;
      }
    }
    &.red {
      fill: $colorRed03;
      &.light {
        fill: $colorRed04;
      }

      &.animating {
        @include markerPulsingAnimation(scaleRed, $colorRed02);
        animation: scaleRed 1.5s infinite ease-in-out;
      }
    }
    &.purple {
      fill: $colorPurple02;
      &.light {
        fill: $colorPurple03;
      }

      &.animating {
        @include markerPulsingAnimation(scalePurple, $colorPurple02);
        animation: scalePurple 1.5s infinite ease-in-out;
      }
    }
    &.grey {
      fill: $colorGrey01;
      &.light {
        fill: $colorGrey03;
      }

      &.animating {
        @include markerPulsingAnimation(scaleGrey, $colorGrey01);
        animation: scaleGrey 1.5s infinite ease-in-out;
      }
    }
    @include diagramFills();

    &.transparent {
      fill-opacity: 0.7;
    }
  }

  .range {
    &.primary, &.blue {
      stroke: $colorBlue05;
      fill: $colorBlue05;
    }
    &.yellow {
      stroke: $colorYellow05;
      fill: $colorYellow05;
    }
    &.green {
      stroke: $colorGreen05;
      fill: $colorGreen05;
    }
    &.red {
      stroke: $colorRed05;
      fill: $colorRed05;
    }
    &.purple {
      stroke: $colorPurple05;
      fill: $colorPurple05;
    }
    &.grey {
      stroke: $colorGrey06;
      fill: $colorGrey06;
    }
  }

  &.animate {
    .marker {
      transition: cx 0.3s ease-in-out;
    }
    .range {
      transition: width 0.3s ease-in-out, x 0.3s ease-in-out;
    }
  }

  .distributionText {
    @include type('LabelS');
    font-weight: 400;
    text-anchor: middle;
    alignment-baseline: central;

    &.primary, &.blue {
      fill: $colorBlue03;
    }
    &.yellow {
      fill: $colorYellow03;
    }
    &.green {
      fill: $colorGreen03;
    }
    &.red {
      fill: $colorRed03;
    }
    &.purple {
      fill: $colorPurple02;
    }
    &.grey {
      fill: $colorGrey01;
    }
  }
}
