@import '/src/styles-imports/shared';

.configurationError {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  z-index: zIndex('Error');
  background-color: white;
  
  .content {
    display: block;
    height: 100%;
    padding: $spaceXl;
    box-sizing: border-box;

    white-space: pre-wrap;

    pre {
      text-align: left;
    }
  }
}
